/* @import url("https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,0..1;1,9..144,100..900,0..100,0..1&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,0..1;1,9..144,100..900,0..100,0..1&family=Inter:slnt,wght@-10..0,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,0..1;1,9..144,100..900,0..100,0..1&family=Inter:slnt,wght@-10..0,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@viewport {
  viewport-fit: cover;
}

body,
html {
  /* background: var(--ligth_red, #fffafa); */
  text-align: center;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.title-header {
  text-align: center;
  display: contents;
}

.navbar {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 8px;
}

.logo-navbar {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;

  background-color: white;
}

.logo-navbar img {
  margin-bottom: 20px;
}

.navbar-logo {
  font-family: "Sora", serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: 10%;
  display: flex;
}
/* .navbar-logo-span {
      font-family: "Sora", serif;font-size: 8px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
} */
/* .navbar img {
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
} */

#mark {
  color: var(--Gray_25, #404040);
  font-family: "Sora", serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.08px;
}

.page-title {
  color: var(--primary-70_-b, #ca673c);
  text-align: center;
  font-family: "Sora";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.page-desc {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.img-col {
  display: flex;
  top: 81.897px;
  justify-content: space-between;
}

.love-game-desc {
  color: var(--Gray-25, #404040);
  text-align: center;
  font-family: "Sora", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 15px 0;
}

.buy-btn {
  display: inline-flex;
  padding: 24px 48px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 48px;
  background: #3c0066;
  color: var(--White, var(--White, #fff));
  text-align: center;
  font-family: "Sora", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.send-btn {
  display: inline-flex;
  padding: 24px 48px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 48px;
  color: #3c0066;
  border: 1px solid #3c0066;
  text-align: center;
  font-family: "Sora", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  height: 58px;
  margin-bottom: 25px;
}
.disabled-btn {
  color: #ffffff;
  border: 1px solid #ca673c;
  background-color: #ca673c;
  opacity: 40%;
  cursor: auto;
}
.input-field {
  height: 44px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px solid #808080;
  position: relative;
  box-shadow: 0 1px 2px 0 #1018280d;
}

.input-field input {
  outline: none;
  background-color: initial;
  width: 100%;
  color: #262626;
  font-family: "Sora", serif;
  font-size: 16px;
}

.input-field input[type="tel"] {
  font-size: 15px;
}

.input-error {
  height: 44px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px solid #ff0000;
  color: var(--ai-arrow, #f00);
}
.input-field img {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the positioning as needed */
}

.input-field select {
  text-transform: none;
  color: #262626;
  font-family: "Sora", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  background: var(--ligth_red, #fffafa);
  outline: none;
}

.input-field input::placeholder {
  color: var(--Gray-40, #838383);
  font-family: "Sora", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.form-center label {
  font-family: "Sora", serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
  text-align: left;
  color: #34343e;
  margin: 20px 0 5px 0;
}

.input-field:focus-within {
  background-color: var(--White, var(--White, #fff));
}

.logo-header {
  display: flex;
  max-width: 837px;
  padding: 48px 0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
input {
  outline: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(
    100% - 50px
  ); /* Adjust the value as per your desired space */
}

.content {
  flex: 1;
  min-height: 100vh;
}

.footer {
  flex-shrink: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  z-index: 999;
}
.footer-two-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
}

.footer div {
  display: flex;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.resend-code {
  font-family: "Sora", serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: var(--grey-basic-85-text-new-black, #010101);
  text-align: center;
}

.resend-code span {
  font-weight: bold;
  border-bottom: 1px solid #262626;
  padding-bottom: 2px;
}
.resend-code h4 {
  font-weight: 500;
}
.send-code {
  display: flex;
  justify-content: center;
  gap: 4px;
}
.send-code p {
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #262626;
}
.send-code span {
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.enter-code {
  font-family: "Sora", serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
  margin-top: 50px;
}

.char-boxes {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.char-boxes input {
  width: 76px;
  height: 76px;
  border-radius: 15px;
  border: 1px solid #e6e6e6;
  font-family: "Sora", serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0;
  text-align: center;
}
.input-has-value {
  border: 1px solid #262626 !important;
  outline: none;
}

.char-boxes input:focus-within {
  border: 1px solid #262626;
}

.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex: 1 0 0;
  width: 100%;
  padding: 16px;
}

.payment button {
  padding: 16px 0;
}

.payment img {
  width: 100%;
}

.apple-pay {
  max-width: 67px;
  background: url("/images/buyapp.png"), lightgray 50% / cover no-repeat;
}
.congrats-bg {
  background: url("/images/congrats-bg.svg") lightgray 50% / cover no-repeat;
  height: 405px;
  width: 266px;
  border-radius: 30px;
}
.selected-method {
  border-radius: 16px 0 0 0;
  border: 1px solid #ca673c !important;
  background: #e2b7a7;
}
.selected-method input {
  background: red;
}
.payment-methods .credit-card {
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  background: transparent;
}

.payment-methods p {
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
}
.accept-terms {
  width: 100%;
}
.accept-terms p {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #262626;
}
.accept-terms p.error-text {
  color: #ff0000;
}
.accept-terms span.error-text {
  color: #ff0000;
  border-bottom: 1px solid #ff0000;
}
.accept-terms input[type="checkbox"].error-text:not(:checked)::before {
  border-color: #ff0000;
}
.accept-terms span {
  color: #ca673c;
  border-bottom: 1px solid #ca673c;
  padding-bottom: 2px;
}
.accept-terms input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  /* border: 2px solid #e100e5; */
  border-radius: 50%;
  outline: none;
  transition: all 0.3s ease;
  position: relative;
}

.accept-terms input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%) rotateY(180deg) rotate(45deg);
  width: 12px;
  height: 7px;
  background-color: #ca673c;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.accept-terms input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.accept-terms input[type="checkbox"]:checked::before {
  background-color: #ca673c;
}
.accept-terms input[type="checkbox"]:not(:checked)::before {
  border: 1px solid #8f8fa7; /* Set grey border for unchecked state */
}
.visit-app {
  @apply mb-5;
}

.upper-frame {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
}

.lower-frame {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  right: 0;
  z-index: -1;
}

.first-frame {
  border-bottom-right-radius: 20%;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 35px 45px 0 rgba(0, 0, 0, 0.5);
}

.second-frame {
  border-bottom-left-radius: 20%;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 35px 45px 0 rgba(0, 0, 0, 0.5);
}

.third-frame {
  border-top-right-radius: 20%;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.5);
}

.fourth-frame {
  border-top-left-radius: 20%;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.5);
}

.center-page {
  display: flex;
  padding: 0 16px 0 16px;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  gap: 8px;
  height: 100%;
  width: 100%;
  min-height: 75vh;
}

.form-center {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  height: 100%;
  width: 100%;
}
.welcome-screen {
  background: url("/images/landing-bg.png"), lightgray 50% / cover no-repeat;
}

.box-center {
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  margin: 0 auto;
  /* align-items: center; */
  justify-content: flex-start;
  text-align: center;
  gap: 8px;
}

.home-info {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 10%;
  transform: translate(-50%, -50%) scale(0.7);
}

.self-stretch {
  display: flex;
}

.self-stretch > .ccc {
  width: 33.33%;
}

.self-stretch > .mmm {
  width: 66.66%;
}

.self-stretch input[type="text"],
.self-stretch input[type="tel"] {
  width: 100%;
  line-height: 2;
}

.rfs ul {
  width: 70vw;
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 40px;
}

.rfs [data-testid="rfs-selected-flag"] {
  display: none;
}

.rfs #rfs-btn {
  margin-left: -15px;
}

.input-field input::placeholder {
  font-size: 14px;
}
.slider {
  width: 77px;
  height: 8px;
  border-radius: 4px;
  background: #ca673c;
}
.country-selector {
  height: 44px;
  border-radius: 12px;
  border: 1px solid #e6e6e6;
  display: flex;
  padding: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 #1018280d;
}
.country-selector div {
  width: 77px;
  border-right: 1px solid #e6e6e6;
  justify-content: space-evenly;
  display: inline-flex;
}
.country-selector select::-ms-expand {
  display: none;
}
.country-selector input::placeholder {
  padding: 10px;
}
.country-selector input {
  padding: 10px;
}
.code-error {
  border-color: #ff0000 !important;
  color: #0a0300;
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
}
.code-error-msg {
  color: var(--before-warning, #e23d3d);
  font-family: "Sora", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
}
/* .footer-right-btn {
  width: 164px;
  height: 58px;
  padding: 14 40 14 40;
  gap: 12px;
  border-radius: 40px;
  background: #6800b2;
  color: #ffffff;
  font-family: "Sora", serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.footer-left-btn {
  width: 164px;
  height: 58px;
  padding: 14 40 14 40;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #3c0066;
  color: #3c0066;
  font-family: "Sora", serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
} */
.footer-single-btn-dark {
  width: 220px;
  height: 40px;
  gap: 12px;
  border-radius: 40px;
  background: #0a0300;
  color: #fae5dc;
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
}

.footer-single-btn-light {
  width: 343px;
  height: 58px;
  padding: 14px 40px 14px 40px;
  border-radius: 40px;
  background: #ffffff;
  color: #6420aa;
  font-family: "Sora", serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.pre-payment-screen {
  margin: 0;
  padding: 30px;
  background-image: url("/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  background-color: #290045;
}
.pre-payment-screen h1 {
  font-family: "Sora", serif;
  font-size: 52px;
  font-weight: 900;
  line-height: 56px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #fffafa;
  margin: 100px 0 50px 0;
}
.pre-payment-screen h2 {
  font-family: "Sora", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  color: #fffafa;
  margin-bottom: 100px;
}
.pre-payment-screen h3 {
  font-family: "Sora", serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 37.57px;
  text-align: center;
  color: #fffafa;
}
.pre-payment-screen span {
  font-family: "Sora", serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  text-align: center;
  color: #fffafa;
}
.radio-btn-field {
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
  justify-content: space-between;
  border: 1px solid #d3d0dd;
}
.radio-btn-field img {
  text-align: right;
  object-fit: contain;
}
.congratulation-screen {
  margin: 0;
  padding: 0;
  background-image: url("/images/congrats-page-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.close-screen-icon {
  display: flex;
  justify-content: end;
  margin: 1px;
  height: 60px;
}
.close-screen-icon img {
  width: 32px;
  height: 32px;
  margin: 10px;
}
.termsModal strong {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}
.termsModal .terms-body {
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
  gap: 30px;
  margin-top: 30px;
  height: 70vh;
}
.termsModal .terms-body {
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
  gap: 30px;
  margin-top: 30px;
  height: 70vh;
}
.termsModal .terms-body::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.termsModal .terms-body::-webkit-scrollbar-track {
  background-color: #9600991a; /* Color of the track (the area behind the scrollbar) */
  border-radius: 8px;
}

.termsModal .terms-body::-webkit-scrollbar-thumb {
  background-color: #96009966; /* Color of the scrollbar thumb (the draggable part) */
  border-radius: 8px;
}

.termsModal .terms-body::-webkit-scrollbar-thumb:hover {
  background-color: #9600991a; /* Color of the scrollbar thumb on hover */
  border-radius: 8px;
}
.termsModal ul {
  list-style: disc;
  padding: 0 25px;
}
.termsModal li {
  font-family: "Sora", serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}
.termsModal .termsTitle {
  font-family: "Sora", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
}

.ReactFlagsSelect-module_secondaryLabel__37t1D {
  font-family: "Sora", serif;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #262626 !important;
}
.phone {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #262626;
  padding: 10px;
}
.phone::placeholder {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #262626;
}
.payment-methods input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  /* border: 2px solid #e100e5; */
  border-radius: 50%;
  outline: none;
  transition: all 0.3s ease;
  position: relative;
}

.payment-methods input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background-color: #ca673c;
  border-radius: 50%;
}

.payment-methods input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.payment-methods input[type="radio"]:checked::before {
  border: 2px solid #ca673c; /* Set grey border for unchecked state */
  width: 24px;
  height: 24px;
}
.payment-methods input[type="radio"]:not(:checked)::before {
  border: 2px solid #8f8fa7; /* Set grey border for unchecked state */
  width: 24px;
  height: 24px;
}
.payment-title {
  font-family: "Sora", serif;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ca673c;
}
.payment-title p {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 35px 0;
  color: #000000;
}
.payment-success-card {
  width: 343px;
  padding: 24px 30px;
  bottom: -9vh;
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 8px 24px 0 #3c00661f;
  left: 75px;
}
.payment-success span {
  font-family: "Sora", serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #262626;
}
.payment-success h2 {
  color: var(--grey-basic-85-text-new-black, #010101);
  text-align: center;
  font-family: "Sora", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.payment-success h3 {
  color: var(--primary-70_-b, #ca673c);
  text-align: center;
  font-family: "Sora", serif;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}
.payment-success-card h2 {
  font-family: "Sora", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #34343e;
  margin: 10px 0;
}
.payment-success-card h3 {
  font-family: "Sora", serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #111111;
  margin: 10px 0;
}
.payment-success-card img {
  width: 56px;
  height: 56px;
  left: 120px;
  gap: 0;
}
.payment-success-card p {
  font-family: "Sora", serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #34343e;
}
.payment-success-card span {
  font-family: "Sora", serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.InputElement::placeholder {
  font-family: "Sora", serif;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 24px;
  text-align: left;

  letter-spacing: 4px !important;
}
.horizontal-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
.dashed-horizontal-line {
  border: none;
  border-top: 1px dashed #34343e;
  margin: 20px 0;
}
.circular-border {
  position: relative;
  width: 340px;
  height: 340px;
  border-radius: 50%;
  margin-top: 40px;
  overflow: hidden;
  border: 2px solid #3c0066;
  padding: 10px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-button img {
  width: 50px;
  height: 50px;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.error-text-validation {
  font-family: "Sora", serif;
  color: var(--before-warning, #e23d3d);

  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.payment-success-bg {
  background: url("/images/unlock-bg.png") lightgray 50% / cover no-repeat;
  height: 405px;
  width: 266px;
  border-radius: 30px;
  position: relative;
  left: -14vh;
}
